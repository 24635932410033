import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";
import MaskedTextInput from "react-text-mask";
import {
  getWebSitesByInn,
  getWebSitesParsingsByIdList,
} from "../../api/requests";
import { Heading } from "../../components/Heading";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Table";
import { useCompanyData } from "../../hooks/api/useCompanyData";
import { formatDate } from "../../utils/formatDate";
import { WebSiteParsingData } from "../../api/types";

const MAX_TEXT_LENGTH = 70;

export const WebSite = () => {
  const { companyId } = useParams();
  const { companyData, industryData } = useCompanyData(companyId);

  const [startMonth, setStartMonth] = useState<Date | null>();
  const [endMonth, setEndMonth] = useState<Date | null>();

  const [expandedMessageId, setExpandedMessageId] = useState<number>(-1);

  const { data: webSites, isLoading } = useQuery({
    queryKey: ["webSites", companyData?.inn || ""],
    queryFn: async () => await getWebSitesByInn(companyData?.inn || ""),
    enabled: !!companyData,
  });

  const { data: webSitesParsings, isLoading: isParsingsLoading } = useQuery({
    queryKey: ["webSitesParsings"],
    queryFn: async () =>
      await getWebSitesParsingsByIdList(
        webSites?.map((_) => _.website_parsing_tab_id || 0) || [],
      ),
    enabled: !!webSites,
  });

  const filteredByDateWebSites = useMemo(
    () =>
      webSites?.filter((_) => {
        if (!_.parsing_date) {
          return true;
        }

        const date = new Date(_.parsing_date || "");

        return (
          (!startMonth || date >= startMonth) && (!endMonth || date <= endMonth)
        );
      }),
    [webSites, startMonth, endMonth],
  );

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name}</Heading>
      <Heading size="S" className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </Heading>
      <div className="flex gap-12 mb-10 text-primary-text">
        <div>
          <div className="select-none text-secondary-text">Месяц начала</div>
          <DatePicker
            showMonthYearPicker
            value={
              startMonth ? moment(startMonth).format("MM.YYYY") : undefined
            }
            placeholderText="__.____"
            onChange={setStartMonth}
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            className="px-4 py-2.5 text-base border border-darkGray border-opacity-40 rounded"
          />
        </div>
        <div>
          <div className="select-none text-secondary-text">Месяц конца</div>
          <DatePicker
            showMonthYearPicker
            value={endMonth ? moment(endMonth).format("MM.YYYY") : undefined}
            placeholderText="__.____"
            onChange={setEndMonth}
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            className="px-4 py-2.5 text-base border border-darkGray border-opacity-40 rounded"
          />
        </div>
      </div>
      <Table
        isSortable={true}
        alignTop={true}
        cols={["Дата парсинга", "URL", "Текст"]}
        rows={filteredByDateWebSites?.map((_, i) => {
          const parsing: WebSiteParsingData =
            webSitesParsings?.find(
              (__) => __.id === _.website_parsing_tab_id,
            ) || {};

          return [
            { cell: formatDate(_.parsing_date), sortValue: _.parsing_date },
            {
              cell: (
                <Link
                  key={i + "-url"}
                  to={parsing?.url || ""}
                  className="text-link hover:underline"
                  target="_blank"
                >
                  {parsing?.url}
                </Link>
              ),
              sortValue: parsing?.url,
            },
            {
              cell: (
                <div key={i + "-text"}>
                  {expandedMessageId === _.id
                    ? parsing?.webpage_text
                    : parsing?.webpage_text
                        ?.substring(0, MAX_TEXT_LENGTH)
                        ?.trim() + "..."}
                  <button
                    className="text-main hover:underline ml-1"
                    onClick={
                      expandedMessageId === _.id
                        ? () => setExpandedMessageId(-1)
                        : () => setExpandedMessageId(_.id || -1)
                    }
                  >
                    {expandedMessageId === _.id ? "Свернуть" : "Развернуть"}
                  </button>
                </div>
              ),
              sortValue: parsing?.webpage_text?.toLowerCase(),
            },
          ];
        })}
      />
      {isLoading || isParsingsLoading ? <Loader /> : null}
      {!!webSites?.length && !filteredByDateWebSites?.length ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные по новостям о компании за выбранное время отсутствуют.
          Попробуйте изменить период фильтрации.
        </Heading>
      ) : null}
      {!webSites?.length && !isLoading && !isParsingsLoading ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные о парсинге веб-сайтов компании отсутствуют
        </Heading>
      ) : null}
    </div>
  );
};
