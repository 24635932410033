export const EthicsIndex = () => {
  const el = window?.document?.getElementsByClassName(
    "z-10 sticky top-0 flex justify-between gap-12 bg-main py-5 px-24 select-none w-full",
  );

  return (
    <iframe
      src="https://index-ai.ethics.hse.ru/company/?isiframe=true"
      width="100%"
      height="1000"
      frameBorder="0"
    ></iframe>
  );
};
