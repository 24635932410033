import { API_BASE_URL, getRequest, LOGIN_API_BASE_URL } from "./methods";
import {
  type AccountData,
  type AuthData,
  type Company,
  type CompanyResponse,
  type GeneralRating,
  type Industry,
  type LetterData,
  type NewsItem,
  type RatingESG,
  type RatingEthics,
  type Region,
  type ReportData,
  type Reuter,
  type ReuterModel,
  type WebSiteData,
  type WebSiteParsingData,
} from "./types";

export const getGeneralRatings = () =>
  getRequest<GeneralRating[]>("/general-ratings");

export const getRatingById = (id: number) =>
  getRequest<GeneralRating>(`/general-ratings/${id}`);

export const getESGRatingsByIdList = (idList: number[]) =>
  getRequest<RatingESG[]>(
    `/ratings-esg/batch-esg/?ids=${idList.map((_) => _ + "&").join("ids=")}`,
  );
export const getESGRatingsByInn = (inn: string) =>
  getRequest<RatingESG[]>(`/ratings-esg/rating-esg-by-company-inn/${inn}`);

export const getEthicsRatingsByIdList = (idList: number[]) =>
  getRequest<RatingEthics[]>(
    `/ratings-ethics/batch-ethics/?ids=${idList.map((_) => _ + "&").join("ids=")}`,
  );
export const getEthicsRatingsByInn = (inn: string) =>
  getRequest<RatingEthics[]>(
    `/ratings-ethics/rating-ethics-by-company-inn/${inn}`,
  );

export const getCompanies = () => getRequest<CompanyResponse[]>("/companies");

export const getCompanyById = (id: number) =>
  getRequest<Company>(`/companies/${id}`);

export const getIndustries = () => getRequest<Industry[]>(`/industries`);

export const getRegions = () => getRequest<Region[]>(`/regions`);

export const getIndustryById = (id: number) =>
  getRequest<Industry>(`/industries/${id}`);

export const getReportsByInn = (inn: string) =>
  getRequest<ReportData[]>(`/reports/reports-by-company-inn/${inn}`);

export const getWebSitesByInn = (inn: string) =>
  getRequest<WebSiteData[]>(`/websites/websites-by-company-inn/${inn}`);

export const getWebSitesParsings = () =>
  getRequest<WebSiteParsingData[]>(`/websites-parsing`);
export const getWebSitesParsingsByIdList = (ids: number[]) =>
  getRequest<WebSiteParsingData[]>(
    `/websites-parsing/batch-websites-parsing/?ids=${ids.map((_) => _ + "&").join("ids=")}`,
  );

export const getNewsByInn = (inn: string) =>
  getRequest<NewsItem[]>(`/news/news-by-company-inn/${inn}`);

export const getReuters = () => getRequest<Reuter[]>("/reuter");

export const getReuterModels = () =>
  getRequest<ReuterModel[]>("/reuter-models");

export const getLettersData = () => getRequest<LetterData[]>("/letter_color");

export const downloadDocumentByName = async (name?: string) =>
  fetch(`${API_BASE_URL}/reports/download-txt/${name}/`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name || "";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Ошибка при скачивании файла:", error));

export const openPDFByName = async (name?: string) =>
  fetch(`${API_BASE_URL}/reports/download-pdf/${name}/`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Ошибка при скачивании файла:", error));

export const logout = () => window.localStorage.removeItem("token");

export const login = (data: AuthData) =>
  fetch(`${LOGIN_API_BASE_URL}/accounts/authenticate`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    const body = await res.json();
    localStorage.setItem("token", body.token);
    localStorage.setItem("userId", body.userId);
  });

export const getUserData = async (userId: string) => {
  const data = await fetch(
    `${LOGIN_API_BASE_URL}/accounts/get-account/${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token") || "",
      },
    },
  );

  if (data.status === 401) {
    logout();
  }

  return await data.json();
};

export const setUserData = async (userId: string, userData: AccountData) => {
  const data = await fetch(
    `${LOGIN_API_BASE_URL}/accounts/update-account/${userId}`,
    {
      method: "PUT",
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token") || "",
      },
    },
  );

  if (data.status === 401) {
    logout();
  }

  return await data.json();
};
